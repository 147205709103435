.containered {
    padding-bottom: 1rem;
}
.promolistwrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
}

.promolist {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    @media (786px <= width <= 1920px) {
        width: 100%;
        padding: 1rem;
    }
    @media (width <= 768px) {
        width: 100%;
        padding: 1rem;
    }
}

.promolistitemcontenttitle {
    font-size: 20px;
    font-weight: 600;
}

.promolistitem {
    padding: 0.75rem;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    text-decoration: none !important;
    color: black !important;
    @media (width <= 768px) {
        width: 100%;
    }
}

.promolistitemimg {
    @media (width <= 768px) {
        width: 100%;
    }
    & img {
        @media (width <= 768px) {
            width: 100%;
        }
        object-fit: cover;
    }
}

.title {
    font-size: 1.5rem;
    font-weight: 600;
}

.thumbs {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr)); /* grid-cols-2 */
    width: 100%; /* w-full */
    gap: 20px 20px; /* gap-x-5 gap-y-5 */
    margin-top: 1rem; /* mt-16 */
    padding-bottom: 2rem;
}
@media (min-width: 640px) {
    .thumbs {
        grid-template-columns: repeat(2, minmax(0, 1fr)); /* md:grid-cols-3 */
    }
}

@media (min-width: 768px) {
    .thumbs {
        grid-template-columns: repeat(3, minmax(0, 1fr)); /* md:grid-cols-3 */
    }
}

@media (min-width: 1280px) {
    .thumbs {
        grid-template-columns: repeat(4, minmax(0, 1fr)); /* xl:grid-cols-4 */
    }
}

@media (min-width: 1440px) {
    .thumbs {
        grid-template-columns: repeat(5, minmax(0, 1fr)); /* 2xl:grid-cols-5 */
    }
}
