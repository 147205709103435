.icon {
    filter: invert(1);
    cursor: pointer;
}
.logo .logoText::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: white;
    z-index: 10;
    left: -25px;
    top: 0;
}
//.icon:hover {
//    background-color: #333333;;
//}

.salesDialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
}

.salesDialogBox {
    width: 50%;
    height: 40%;
    background-color: var(--croonus-white);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    overflow: auto;
}

.salesDialogHeader {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}

.salesDialogHeader h2 {
    font-size: 1.5rem;
    font-weight: 500;
}

.salesDialogHeader .close {
    font-size: 1.5rem;
    cursor: pointer;
}

.salesDialogBody {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.salesDialogNoSales {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.salesDialogBody img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.tbl {
    width: 100%;
    overflow-y: auto;
}
.tbl > * {
    border-width: 5px;
    border-style: solid;
    border-color: transparent;
}
.salesDialogFooter {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.salesDialogFooter button {
    width: 100%;
    height: 100%;
    background-color: var(--croonus-primary);
    color: var(--croonus-white);
    border: none;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .salesDialogBox {
        width: 100%;
        height: 100%;
        padding: 0;
    }
    .salesDialogBoxHeader {
        padding: 0;
    }
    .salesDialogBoxHeader h2 {
        font-size: 1.2rem;
    }
    .salesDialogBoxHeader .close {
        font-size: 1.2rem;
    }
    .salesDialogBoxBody {
        height: 90%;
    }
    .salesDialogBoxBody img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .salesDialogBoxFooter {
        height: 10%;
    }
    .salesDialogBoxFooter button {
        font-size: 1rem;
    }
}

.wrapper {
    position: fixed;
    width: 100%;
    height: 60px;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 200;
    padding: 0.25rem 0.75rem;
    top: 0;
    left: 0;
}

.leftWrapper {
    padding-left: 2.6rem;
    height: 100%;
    display: flex;
    min-width: 40%;
    align-items: center;
    justify-content: space-between;
}

.logo {
    height: 90%;
    max-width: 50%;
    margin: 0 1.5rem;
    img {
        display: block;
        height: 100%;
    }
}
.headerLinks {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
}

.linksDesktop {
    display: flex;
    gap: 2rem;
}

.linksMobile {
    display: none;
}

.burger {
    color: var(--croonus-white);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .linksDesktop {
        display: none;
    }

    .linksMobile {
        display: flex;
        gap: var(--gap-half);
    }
    .burger {
        display: none;
    }
    .leftWrapper {
        padding-left: 0;
    }
    .logo {
        height: 60%;
        margin: 0;
    }
    .headerLinks {
        margin: 0;
    }
}

@media print {
    .wrapper {
        display: none;
    }
}
