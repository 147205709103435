.background-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 20px;
    img {
        width: 100%;
        height: 100%;
        position: relative;
        left: 34%;
        object-fit: cover;
    }
    div {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(80deg, rgb(50, 50, 50) 0%, rgba(50, 50, 50, 1) 50%, rgba(50, 50, 50, 0.2) 100%);
    }
}

.login-container {
    min-height: 100vh;
    background-color: #606060;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 5rem;
    width: 100%;
}
.login-card {
    background-color: #333;
    border-radius: 20px;
    width: 100%;
    min-height: 100%;
    position: relative;
    box-shadow: 20px 20px 15px 5px var(--border-color);
    .login-card-gray-overlay {
        padding: 4rem;
        display: grid;
        align-content: center;
        position: relative;
        border-radius: 20px;
        height: 100%;
    }
}

.logo {
    max-height: 100px;
    max-width: 100%;
}
.login-sub-title {
    margin-top: 3rem;
    color: var(--croonus-white);
}
.message {
    color: var(--croonus-white);
}
.company-title {
    color: var(--croonus-white);
    font-weight: 600;
}
.big-dot {
    color: var(--primary-color);
    font-size: 5rem;
    line-height: 1rem;
}
.input-container {
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
    .input {
        background-color: #606060;
        border: none;
        color: white;
        border-radius: 15px;
        font-size: 1.5rem !important;
        margin-bottom: unset;
    }
    .input:focus {
        border: none;
        background-color: #606060;
        color: white;
        box-shadow: unset;
        border-radius: 15px;
    }
}
.login-submit-container {
    width: 100%;
}
.login-submit {
    width: 100%;
    background-color: var(--primary-color);
    border: none;
    padding: 0.8rem 0;
    color: var(--croonus-white);
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 15px;
}
.login-submit:hover {
    background-color: var(--primary-color-hover);
}
.powered {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: var(--croonus-white);
    font-weight: 600;
    .paragraph {
        padding-top: 1rem;
    }
    img {
        max-width: 100%;
    }
}

.logoHolder .logoText::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: white;
    z-index: 10;
    left: -25px;
    top: 0;
}

.create-new {
    text-decoration: none;
    color: var(--primary-color);
}
.create-new:hover {
    color: var(--primary-color-hover);
}
.label {
    color: var(--croonus-white);
}
.form {
    margin-top: 2rem;
    background-color: transparent;
    color: white;
    select,
    input,
    textarea {
        background-color: #606060 !important;
        border: none;
        color: white !important;
        border-radius: 15px;
        font-size: 1.5rem !important;
        margin-bottom: unset;
    }
    input:focus {
        border: none;
        background-color: #606060 !important;
        color: white !important;
        box-shadow: unset;
        border-radius: 15px;
    }
}

@media (min-width: 1920.2px) {
    .login-container {
        margin: auto;
        align-items: center;
    }
    .login-card {
        height: 100%;
        min-height: unset;
        width: 1800px;
    }
}
@media (max-width: 1199.98px) {
    .background-wrapper {
        img {
            position: relative;
        }
    }
}
@media (max-width: 991.98px) {
    .login-card {
        .login-card-gray-overlay {
            padding: 2rem;
        }
    }
    .login-container {
        padding: 3rem;
    }
}

@media (max-width: 767.98px) {
    .login-container {
        padding: unset;
        align-items: unset;
    }
    .login-card {
        border-radius: unset;
    }
    .background-wrapper {
        border-radius: unset;
    }
}

@media (max-width: 575.98px) {
    .powered {
        flex-direction: column;
    }
    .login-card {
        .login-card-gray-overlay {
            padding: 2rem 1rem;
        }
    }
    .login-sub-title {
        font-size: 1.125rem;
        margin-top: 2rem;
    }
    .company-title {
        font-size: 1.375rem;
    }
    .big-dot {
        font-size: 3rem;
    }
}
