.wrapper {
    h4 {
        &:not(:first-child) {
            margin-top: calc(2 * var(--gap));
        }
    }

    @media only screen and (max-width: 1199px) {
        & > div:nth-child(2) h4 {
            margin-top: calc(2 * var(--gap));
        }
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
}

.cart {
    height: fit-content;

    @media only screen and (max-width: 1199px) {
        & {
            max-height: unset;
        }
    }
}

.section {
    margin-bottom: var(--gap);
}

.formSection {
    margin-bottom: 2rem;
}

.title {
    margin-bottom: var(--gap);
}

.prices {
    padding-right: 2rem;
}

.priceBreakdown {
    border-top: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    text-align: end;
}

.continue,
.checkout {
    width: 10rem;
    text-align: center;
    justify-content: center;
}

.continue {
    background-color: var(--primary-color);
    color: var(--croonus-white);
}

.checkout {
    background-color: var(--croonus-black);
    color: var(--croonus-white);
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .forms {
        flex-direction: column-reverse;
    }
    .title {
        text-transform: uppercase;
        margin-bottom: var(--gap-half);
    }
    .formSection {
        padding: var(--gap-half);
        margin-bottom: var(--gap);

        p {
            font-size: 0.9rem;
            margin-bottom: var(--gap-half) !important;
        }
    }
}
