.wrapper {
    padding: 1rem;
    width: 100%;
    padding-left: 21rem;
}

@media (max-width: 768px) {
    .wrapper {
        padding-left: 0;
    }
}
