.promolistwrapper {
    width: 100%;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    @media (0px <= width <= 640px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media (640px <= width <= 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (1024px <= width) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
.lineclamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.promolist {
    width: 100%;

    @media (786px <= width <= 1920px) {
        width: 100%;
        padding: 1rem 1rem 1rem 21rem;
    }
    @media (width <= 768px) {
        width: 100%;
        padding: 1rem;
    }
}

.loaderPromoPage {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem 1rem 21rem;
    /*height: 100%;*/
}

.promolistitemcontenttitle {
    font-size: 20px;
    font-weight: 600;
}

.promolistitem {
    column-span: 1;
    padding: 0.75rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.75rem;
    text-decoration: none !important;
    color: black !important;

    @media (width <= 768px) {
        width: 100%;
    }
}

.promolistitemimg {
    @media (width <= 768px) {
        width: 100%;
    }
    & img {
        @media (width <= 768px) {
            width: 100%;
        }
        object-fit: contain;
    }
}
