.clickable {
    cursor: pointer;
}
.wide {
    width: 100%;
}
.tall {
    min-height: 100%;
}

.flex {
    display: flex;
}
.block {
    display: block;
}

.relative {
    position: relative;
}

.no-padding {
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}

.m {
    margin: var(--gap);
}
.mv {
    margin-top: var(--gap);
    margin-bottom: var(--gap);
}
.mh {
    margin-left: var(--gap);
    margin-right: var(--gap);
}
.mt {
    margin-top: var(--gap);
}
.mr {
    margin-right: var(--gap);
}
.mb {
    margin-bottom: var(--gap);
}
.ml {
    margin-left: var(--gap);
}

.p {
    padding: var(--gap);
}
.pv {
    padding-top: var(--gap);
    padding-bottom: var(--gap);
}
.ph {
    padding-left: var(--gap);
    padding-right: var(--gap);
}
.pt {
    padding-top: var(--gap);
}
.pr {
    padding-right: var(--gap);
}
.pb {
    padding-bottom: var(--gap);
}
.pl {
    padding-left: var(--gap);
}
.bold {
    font-weight: 600;
}
.underline {
    text-decoration: underline;
}

.h-underline {
    position: relative;
    width: fit-content;
    margin-bottom: 2.3rem;
    font-weight: 600;
}

.h-underline:after {
    content: "";
    height: 3px;
    width: 70%;
    background: var(--primary-color);
    position: absolute;
    bottom: -10px;
    right: -14px;
}

.underline {
    position: relative;
    width: fit-content;
    margin: 0.8rem 0;
    text-decoration: none;

    &::after {
        content: "";
        height: 2px;
        width: 3rem;
        background: var(--primary-color);
        position: absolute;
        bottom: -0.3rem;
        right: 0rem;
    }
}

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-bold {
    font-weight: 600;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.page-container {
    padding: var(--gap);
    height: 100%;
}

.page-content {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 0.5rem;
    padding: 2rem;
    height: 100%;
    overflow: auto;
}

@media only screen and (min-width: 768px) {
    .desktop-hidden {
        display: none !important;
    }
}

@media only screen and (max-width: 768px) {
    .page-container {
        padding: 0;
    }
    .page-content {
        border-radius: 0;
        padding: 1rem;
    }
    .mobile-hidden {
        display: none !important;
    }
}

@media print {
    .page-content {
        box-shadow: none;
        padding: 0;
        height: auto;
    }
}
